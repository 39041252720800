.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 17px;
    border: 1px solid #B5B5B5;
    max-width: 300px;
    width: 100%;
    box-sizing: border-box;
}

.DateTime {
    display: flex;
    align-items: center;
    color: #B5B5B5;
    font-size: 0.9rem;
    width: 100%;
    justify-content: space-around; /* Changed from space-between to space-around for equal spacing */
}

.Date,
.Time {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    margin-right: 0.5rem;
    color: #B5B5B5;
}