.main {
    margin-top: 0;
    background-color: black;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 5%;
}

.main h2 {
    background-color: black;
    margin-top: 5%;
}

.table-number-container {
    display: flex;
    color: var(--text-color);
    justify-content: center;
    align-items: center; 
    width: 15rem;
    height: 50px;
    border-radius: 15px;
    text-align: center;
    border: #f83e3e 1px solid;
    font-size: 20px;
    margin-bottom: 2rem;
}

.button-primary {
    display: inline-block;
    padding: 0.5rem 1rem;
    /* Reduced padding */
    border-radius: 1rem;
    /* Adjusted border-radius */
    border: 2px solid #f83e3e;
    background-color: transparent;
    color: #f83e3e;
    font-size: 1rem;
    /* Smaller font-size */
    text-decoration: none;
    transition: background-color 0.2s ease-out;
}

.button-primary:hover {
    background-color: #f83e3e;
    color: #fff;
    border: 2px solid #f83e3e;
    transition: background-color 0.2s ease-out;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.modern-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.title-reservation {
    margin-bottom: 40px;
    color: var(--text-color);
    width: 300px;
    text-align: left;
}

.input-field {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #FF3D00;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
}

.form-container {
    display: flex;
    flex-direction: column;
}

.form-label {
    margin-bottom: 10px;
}

.message {
    color: red;
    margin-top: 10px;
}

.left-side-container {
    flex-grow: 1;
    margin: 20px 20px;
    flex-shrink: 0;
}

.right-side-container {
    flex-shrink: 0;
    margin-left: 20px;
    margin: 20px 30px;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 20px;
    border-radius: 10px;

}

.input-field:focus {
    outline: none;
    border-color: #FF3D00;
}

.button-primary:hover {
    background-color: #E63700;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.message {
    margin-top: 10px;
    color: #FF3D00;
}

.Content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
}

.TableCardContainer {
    flex: 3;
    max-width: 75%;
}

.reservationContainer {
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    justify-items: center;
    border: 1px solid #ccc;
    padding: 1.5em;
}

.form-container-reservation {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}


@media only screen and (max-width: 768px) {
    .main {
        padding: 2%;
    }

    .main h1 {
        font-size: 24px;
    }

    .main button {
        font-size: 14px;
        padding: 8px 20px;
    }

    .TableCardContainer {
        max-width: 100%;
    }

    .reservationContainer {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .form-container-reservation {
        margin-bottom: 40px;
    }

    .left-side-container {
        margin: 20px 0;
    }

    .right-side-container {
        margin: 20px 0;
    }

    .content-container {
        margin-left: 0;
    }
}

.input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid #FF3D00;
    border-radius: 4px;
}
.button-amount-decrease, .button-amount-increase {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: black;
    font-size: 1.2rem;
}

.amount-display-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.amount-label {
    font-weight: bold;
}

.amount-display {
    font-size: 1.2rem;
}

@media only screen and (max-width: 480px) {
    .main h1 {
        font-size: 20px;
    }

    .main button {
        font-size: 12px;
        padding: 6px 16px;
    }

    .reservationContainer {
        padding: 1em;
    }
}