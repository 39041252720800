.card {
    width: 100%;
    margin: 1rem;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
    border-radius: 10px;
    border-left: 5px solid #1976d2;
  }
  
  .dark {
    background-color: var(--dark-bg-color);
    color: var(--text-color);
  }
  
  .light {
    background-color: var(--light-bg-color);
    color: var(--text-color);
  }
  
  .order-heading, .detail, .status, .item-name {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: bold;
  }
  
  .icon, .icon-small {
    vertical-align: middle;
  }
  
  .custom-divider {
    margin: 16px 0;
  }
  
  .status-pending {
    background-color: orange;
  }
  
  .status-cooking {
    background-color: cyan;
  }
  
  .status-completed {
    background-color: green;
  }
  
  .item {
    margin: 8px 0;
    padding: 8px;
  }
  
  .list-item {
    display: block;
  }
  
  .status-select {
    margin-top: 8px;
  }
  
  .total-section {
    font-weight: bold;
  }
  
  .total-price {
    color: green;
  }
  
  /* Add your CSS variables for colors in a global CSS file */
  