@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.nameapp {
  background-color: black;
}

.main {
  margin-top: 0;
  background-color: black;
  color: white;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 5%;
}

.main h2 {
  background-color: black;
  margin-top: 5%;
  color: #FF3D00;
  align-items: center;
  display: flex;
  justify-content: center;
}

.main h1 {
  margin-top: 2%;
  margin-bottom: 2%;
}

.main button {
  background-color: #FF3D00;
  color: white;
  margin-left: 2%;
  margin-right: 5%;
  padding: 10px 40px;
  margin-right: 40px;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 11px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.modern-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.title-reservation {
  margin-bottom: 40px;
  width: 300px;
  text-align: left;
}

.input-field {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #FF3D00;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 10px;
}

.button-primary {
  background-color: #FF3D00;
  color: white;
  margin: 1%;
  padding: 10px 40px;
  margin-right: 40px;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 11px;
}

.message {
  color: red;
  margin-top: 10px;
}

.left-side-container {
  flex-grow: 1;
  margin: 20px 20px;
  flex-shrink: 0;
}

.right-side-container {
  flex-shrink: 0;
  margin-left: 20px;
  margin: 20px 30px;
  /* Add margin to create a gap between the left and right sides */
}

.content-container {
  margin-left: 80px;
  /* Set the margin equal to the width of the sidebar */
}

.input-field:focus {
  outline: none;
  border-color: #FF3D00;
  box-shadow: 0 0 5px rgba(255, 61, 0, 0.5);
}

.button-primary:hover {
  background-color: #E63700;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.message {
  margin-top: 10px;
  color: #FF3D00;
}

.Content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
}

.TableCardContainer {
  flex: 3;
  max-width: 75%;
}

.reservationContainer {
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  justify-items: center;
  border: 1px solid black;
  /* Add a black border */
  padding: 1.5em;
  /* Add some padding */
}

.form-container-reservation {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.TableViewTitle {
  display: flex;
  margin-left: 2.5rem;
  justify-content: space-between;
  align-items: center;
}
