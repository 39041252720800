/* Base Styles */
.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(to right, #f83e3e, #f87c7c);
  height: 100px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo {
  color: #fff;
  font-size: 4rem; /* Adjusted to make it bigger */
  text-decoration: none;
  font-weight: bold;
  margin-top: 30px; /* Add some space to the top of the logo */
  margin-left: 5%;
  transition: color 0.3s ease;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  align-items: center; /* Align items vertically in the center */
}

.nav-links li a {
  color: #fff; /* Default color */
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #000; /* Hover color */
}

.nav-links li a.active {
  color: #000; /* Active color */
}

/* Login Button Styles */
.nav-button {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-button:hover {
  background-color: #fff;
  color: #f87c7c;
}

/* Ensure MenuIcon is white */
.menu-icon .MuiSvgIcon-root {
  color: #fff !important;
}

/* Drawer Styles */
.drawer-header {
  display: flex;
  justify-content: flex-end;
}

.nav-links {
  padding: 0;
}

.nav-links li {
  list-style: none;
}

.nav-links li a {
  color: #fff; /* Default color */
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #000; /* Hover color */
}

.nav-links li a.active {
  background-color: #f83e3e;
  color: #fff; /* Active color */
}

/* Responsive Styles */
@media (max-width: 600px) {
  .nav-links {
    flex-direction: column;
    align-items: center;
  }

  .nav-links li a {
    font-size: 1.2rem;
    padding: 10px;
  }

  .nav-button {
    margin-top: 10px; /* Add some margin to separate it from nav links in mobile view */
  }
}
