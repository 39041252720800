div:has(> .login-container) {
  background: linear-gradient(to right, #f83e3e, #f87c7c);
}

.login-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
  
h1 {
  margin-bottom: 2rem;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 1rem;
}

input[type='email'],
input[type='password'] {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.login-button {
  background-color: #0077b6;
  color: #ffffff;
  padding: 0.75rem 1rem;
  border: none;
  cursor: pointer;
  margin-bottom: 1rem;
}

.login-button:hover {
  background-color: #005f92;
}

.links {
  display: flex;
  justify-content: space-between;
}

.message {
  color: red;
}
item {
  font-size: 16px;
}

/* Tablet styles */
@media (min-width: 768px) {
  .item {
    font-size: 18px;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .item {
    font-size: 20px;
  }
}
  