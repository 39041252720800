.table-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
    padding: 2rem;
    margin: auto;
    background: #222;
    background-image: radial-gradient(circle, #444 1px, transparent 1px);
    background-size: 30px 30px;
    width: 83vw;
    height: 90vh;
    border-radius: 50px;
    box-sizing: border-box;
    overflow: hidden; /* Ensuring content fits without scrollbars */
}

.table-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    transition: transform 0.3s ease;
    cursor: pointer;
    border-radius: 100px;
}

.table-container.circle {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    /* Increased to half of the height for a perfect circle */
}

.table-container.capsule {
    width: 300px;
    height: 150px;
    border-radius: 150px / 75px;
    /* Increased to half of the height for fully rounded ends */
}

.table {
    width: 100%;
    height: 100%;
    background-color: #444;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: inherit; /* Inherit the border-radius from the parent */
}
.table-core {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #555;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    box-shadow: 0 0 12px #fff;
}

/* Table status coloring with glow effect */
.available .table-core {
    background-color: rgb(49, 209, 62);
    box-shadow: 0 0 12px #0f0;
}

.reserved .table-core {
    background-color: #ffa500;
    box-shadow: 0 0 12px #ffa500;
}

.served .table-core {
    background-color: rgb(175, 32, 13);
    box-shadow: 0 0 12px #f00;
}

.chair {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 5px #fff;
    /* Glow effect for chairs */
}

/* Positioning chairs for circle tables */
.chairs-container.chairs-4 .chair:nth-child(1) {
    top: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.chairs-container.chairs-4 .chair:nth-child(2) {
    top: 50%;
    right: -10px;
    transform: translate(50%, -50%);
}

.chairs-container.chairs-4 .chair:nth-child(3) {
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 50%);
}

.chairs-container.chairs-4 .chair:nth-child(4) {
    top: 50%;
    left: -10px;
    transform: translate(-50%, -50%);
}

/* Positioning chairs for capsule tables */
.chairs-container.chairs-6 .chair:nth-child(1),
.chairs-container.chairs-6 .chair:nth-child(6) {
    top: 50%;
    transform: translateY(-50%);
}

.chairs-container.chairs-6 .chair:nth-child(1) {
    left: 0;
}

.chairs-container.chairs-6 .chair:nth-child(6) {
    right: 0;
}

.chairs-container.chairs-6 .chair:nth-child(2),
.chairs-container.chairs-6 .chair:nth-child(5) {
    top: 0;
}

.chairs-container.chairs-6 .chair:nth-child(2) {
    left: 25%;
}

.chairs-container.chairs-6 .chair:nth-child(5) {
    right: 25%;
}

.chairs-container.chairs-6 .chair:nth-child(3),
.chairs-container.chairs-6 .chair:nth-child(4) {
    bottom: 0;
}

.chairs-container.chairs-6 .chair:nth-child(3) {
    left: 25%;
}

.chairs-container.chairs-6 .chair:nth-child(4) {
    right: 25%;
}

.table:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .table-view {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .table-container.circle,
    .table-container.capsule {
        width: 120px;
        height: 120px;
    }

    .table-core {
        width: 30px;
        height: 30px;
    }

    /* Adjust the chair positions for mobile sizes as needed */
}