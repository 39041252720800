/* Define a color palette */
:root {
    --primary-color: #0077B5;
    --secondary-color: #FF9900;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
}

/* Define a typography system */
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1rem;
}

/* Define a button style */
.btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

/* Define a primary button style */
.btn-primary {
    background-color: var(--primary-color);
    color: #fff;
}

/* Define a secondary button style */
.btn-secondary {
    background-color: var(--secondary-color);
    color: #fff;
}

/* Define a success button style */
.btn-success {
    background-color: var(--success-color);
    color: #fff;
}

/* Define a danger button style */
.btn-danger {
    background-color: var(--danger-color);
    color: #fff;
}

/* Define a warning button style */
.btn-warning {
    background-color: var(--warning-color);
    color: #fff;
}

/* Define an info button style */
.btn-info {
    background-color: var(--info-color);
    color: #fff;
}

/* Define a layout system */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.col {
    flex: 1;
    padding: 0 15px;
}

/* Define a set of UI elements */
.btn {
    display: inline-block;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-secondary {
    color: #fff;
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.btn-success {
    color: #fff;
    background-color: var(--success-color);
    border-color: var(--success-color);
}

.btn-danger {
    color: #fff;
    background-color: var(--danger-color);
    border-color: var(--danger-color);
}

.btn-warning {
    color: #333;
    background-color: var(--warning-color);
    border-color: var(--warning-color);
}

.btn-info {
    color: #fff;
    background-color: var(--info-color);
    border-color: var(--info-color);
}

/* Define a set of utility classes */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.pt-1 {
    padding-top: 1rem;
}

.pt-2 {
    padding-top: 2rem;
}

.pt-3 {
    padding-top: 3rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.pb-2 {
    padding-bottom: 2rem;
}

.pb-3 {
    padding-bottom: 3rem;
}

.hero-container {
    height: calc(100vh - 4rem);
    display: flex;
    justify-content: left;
    background: linear-gradient(to right, #f83e3e, #f87c7c);
    align-items: center;
}

.hero-content {
    max-width: 800px;
    text-align: left;
    color: #fff;
    margin-left: 5%;
    z-index: 1;
}

.hero-title {
    font-size: 4rem;
    text-align: left;

    margin-bottom: 1rem;
}

.hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.button-primary {
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 2rem;
    border: 2px solid #f83e3e;
    background-color: transparent;
    color: #f83e3e;
    font-size: 1.5rem;
    text-decoration: none;
    transition: background-color 0.2s ease-out;
}

.button-primary:hover {
    background-color: #f83e3e;
    color: #fff;
    border: 2px solid #f83e3e;
    transition: background-color 0.2s ease-out;
}

.home {
    border: 2px solid #fff;
    color: #fff;
  }
  
  .home:hover {
    background-color: #fff;
    border: 2px solid #f83e3e;
    color: #000;
    animation: scale 0.5s ease-in-out;
  }


.section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 4rem 2rem;
}

.section-content {
    max-width: 800px;
    text-align: left;
    color: #333;
    z-index: 1;
}

.section-title {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.section-text {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.section-image {
    width: 50%;
    height: 100%;
    background-image: url("https://images.unsplash.com/photo-1551963831-b3b1ca40c98e");
    background-size: cover;
    background-position: center;
    filter: brightness(0.5);
    z-index: 0;
}

.footer {
    clear: both;
    bottom: 0;
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 50px 0;
}

div:has(> .footer) {
    display: grid;
    grid-template-rows: 1fr auto;
    min-height: 100vh;
}

div:has(> .footer) {
    min-height: 100svh;
}

.footer h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.footer p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer ul li {
    margin-bottom: 10px;
}

.footer ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-bottom {
    background-color: #222;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
}

.button-outline {
    background-color: transparent;
    color: #ff6b6b;
    border: 2px solid #ff6b6b;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    text-decoration: none;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

.container-stats {
    background-color: #f8f9fa;
    padding: 3rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.button-outline:hover {
    background-color: #ff6b6b;
    color: #fff;
}

.container-stats {
    background-color: #000;
    padding: 3rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.stats {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

.stat {
    text-align: center;
    background-color: #000;
    padding: 2rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.stat-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
}

.stat-number {
    margin: 0;
    font-size: 3rem;
    font-weight: bold;
    color: #ff6b6b;
}

.mid-size-picture {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 400px;
    /* adjust the width as per your requirement */
    height: auto;
}



/* Responsive styles */
@media screen and (max-width: 768px) {
    .hero-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;

    }

    .hero-content {
        margin-bottom: 2rem;
        text-align: center;
    }

    .hero-title {
        text-align: center;
        font-size: 2rem;
    }

    .hero-subtitle {
        font-size: 1.2rem;
    }

    .button-primary {
        font-size: 1.2rem;
        padding: 1rem 2rem;
    }

    .container-stats {
        margin: 2rem 0;
    }

    .stats {
        flex-direction: column;
        align-items: center;
    }

    .stat {
        margin-bottom: 1rem;
    }

    .stat-title {
        font-size: 1.2rem;
    }

    .stat-number {
        font-size: 1.5rem;
    }

    .section-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .section-image {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .section-title {
        font-size: 2rem;
    }

    .section-text {
        font-size: 1.2rem;
    }

    .footer {
        padding: 2rem 0;
    }

    .col {
        margin-bottom: 2rem;
    }
}