.about-container {
    display: flex;
    height: 100vh; /* Adjust the height as needed */
    background-color: #333;
}

.table-view-container {
    flex-grow: 1; /* Ensures that the table view takes up the remaining space */
    position: relative; /* For absolute positioning of children, if needed */
    overflow: auto; /* In case the content overflows */
    padding: 20px; /* Adjust as needed */
}
