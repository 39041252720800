.subscription-container {
    display: flex;
    width: 100%;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
}

.billing-info {
    flex-grow: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.billing-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
}

.billing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.billing-title {
    font-weight: bold;
    color: #E53935;
}

.status-indicator {
    display: flex;
    align-items: center;
}

.active-status {
    color: #43A047;
    margin-right: 8px;
}

.next-billing {
    margin-top: 20px;
}

.next-billing .MuiTypography-subtitle1 {
    font-weight: bold;
    color: #E53935;
    padding-bottom: 12px;
}

.next-billing .MuiTypography-root {
    color: var(--text-color);
}

.billing-details-divider {
    height: 2px;
    background-color: #E0E0E0;
    margin: 20px 0;
}

.billing-details {
    overflow-y: auto;
    max-height: 400px;
    margin-bottom: 20px;
}

.payment-history-container {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 5px;
}

.payment-history-title {
    font-weight: bold;
    margin-bottom: 15px;
    color: #E53935;
}

.invoice-card {
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.invoice-date {
    font-size: 0.9rem;
    color: #757575;
}

.invoice-amount {
    font-weight: bold;
    color: #4CAF50;
}

.payment-method {
    margin-top: 20px;
    position: relative;
}

.payment-method:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #E53935;
}

.payment-method .MuiTypography-subtitle1 {
    font-weight: bold;
    color: #E53935;
    padding-top: 12px;
}

.payment-method-details {
    display: grid;
    grid-template-columns: 24px auto;
    gap: 0 10px;
    align-items: center;
    margin-top: 15px;
    padding: 15px;
    border-radius: 8px;
}

.payment-method-details svg {
    fill: #E53935;
}

.payment-method-details div {
    margin-left: 10px;
}

.payment-method-details .MuiTypography-root {
    display: block;
    color: #333;
    margin: 2px 0;
}

.payment-method-details .MuiTypography-root:first-child {
    font-weight: bold;
    color: #E53935;
}

.payment-method-details .MuiTypography-root:not(:first-child) {
    font-size: 0.9rem;
    color: var(--text-color);
}

.MuiButton-root {
    margin-top: 10px;
    background-color: #2196f3;
    color: white;
}

.MuiButton-root:hover {
    background-color: #1976d2;
}

.cancel-button, .download-button {
    padding: 8px 16px;
    font-size: 0.875rem;
    text-transform: none;
    border-radius: 4px;
    line-height: 1.75;
    font-weight: bold;
}

.cancel-button {
    background-color: #ff1744;
}

.cancel-button:hover {
    background-color: #f01440;
}

.download-button {
    background-color: #4caf50;
}

.download-button:hover {
    background-color: #43a047;
}

.order-item .price {
    color: #4CAF50;
    font-weight: bold;
}

@media (max-width: 768px) {
    .billing-card {
        width: 100%;
    }

    .billing-header, .payment-method-details, .billing-details {
        flex-direction: column;
    }

    .status-indicator, .order-item, .payment-method-details div {
        align-items: flex-start;
    }

    .MuiButton-root {
        width: 100%;
        margin-top: 10px;
    }
}
