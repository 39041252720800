:root {
    --primary-color: #0077B5;
    --secondary-color: #FF9900;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --light-background: #f8f9fa;
    --text-color: #333;
    --white-color: #fff;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1rem;
}

.hero-container {
    display: flex;
    background: linear-gradient(to right, #f83e3e, #f87c7c);
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh;
}

.hero-content {
    max-width: 800px;
    text-align: left;
    color: var(--white-color);
    width: 100%;
}

.hero-title {
    font-size: 3rem;
    margin-bottom: 0.5rem;
}

.hero-subtitle {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.button-primary {
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 1rem;
    text-decoration: none;
    transition: background-color 0.2s ease-out;
    display: inline-block;
}

.button-primary:hover {
    background-color: #f83e3e;
    color: var(--white-color);
}

.home {
    border: 2px solid var(--white-color);
    color: var(--white-color);
}

.home:hover {
    background-color: var(--white-color);
    border: 2px solid #f83e3e;
    color: var(--text-color);
    animation: scale 0.5s ease-in-out;
}

.features-section {
    padding: 4rem 2rem;
    background-color: var(--light-background);
    text-align: center;
}

.feature-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.feature-card {
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 2rem;
    width: 300px;
    text-align: center;
}

.feature-icon {
    margin-bottom: 1rem;
    color: var(--primary-color);
}

.feature-card-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.feature-card-description {
    font-size: 1rem;
    color: #666;
}

.business-value-section {
    padding: 4rem 2rem;
    background-color: var(--light-background);
    text-align: center;
}

.section-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--text-color);
}

.section-title .highlight {
    color: var(--primary-color);
}

.section-subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
}

.business-value-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.business-value-card {
    background-color: var(--white-color);
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 300px;
    text-align: left;
    position: relative;
}

.business-value-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--text-color);
}

.business-value-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
}

.card-visual, .transaction-visual, .budget-visual {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
}

.card-visual img, .transaction-visual img, .budget-visual img {
    max-width: 100%;
    border-radius: 10px;
}

.card-info {
    font-size: 0.9rem;
    color: var(--text-color);
    margin-top: 1rem;
}

.pricing-section {
    padding: 4rem 2rem;
    background-color: var(--light-background);
    text-align: center;
}

.section-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--text-color);
}

.section-title .highlight {
    color: #f83e3e;
}

.section-subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
}

.billing-options {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.billing-option {
    background-color: transparent;
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
}

.billing-option.active, .billing-option:hover {
    background-color: #f83e3e;
    color: var(--white-color);
    border: 1px solid #f83e3e;
}

.billing-option .discount {
    background-color: #f83e3e;
    color: var (--white-color);
    border-radius: 10px;
    padding: 0.2rem 0.5rem;
    margin-left: 0.5rem;
    font-size: 0.8rem;
}

.pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.pricing-card {
    background-color: var(--white-color);
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 300px;
    position: relative;
    text-align: left;
}

.pricing-card.most-popular {
    border: 2px solid #f83e3e;
}

.pricing-card-header {
    margin-bottom: 1rem;
}

.pricing-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--text-color);
}

.pricing-description {
    font-size: 1rem;
    color: #666;
}

.pricing-price {
    font-size: 2rem;
    color: #f83e3e;
    margin-bottom: 1rem;
}

.pricing-price span {
    font-size: 1rem;
    color: #666;
}

.pricing-features {
    list-style: none;
    padding: 0;
    margin-bottom: 1.5rem;
}

.pricing-features li {
    margin-bottom: 0.5rem;
    color: var(--text-color);
}

.button-primary {
    background-color: #f83e3e;
    color: var(--white-color);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    text-decoration: none;
    transition: background-color 0.2s, color 0.2s;
}

.button-primary:hover {
    background-color: var(--white-color);
    color: #f83e3e;
    border: 1px solid #f83e3e;
}

.most-popular-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #f83e3e;
    color: var(--white-color);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.8rem;
}

.cta-section {
    padding: 4rem 2rem;
    background-color: #0077B5;
    text-align: center;
    color: var(--white-color);
}

.cta-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.cta-description {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.cta-button {
    background-color: var(--white-color);
    color: #0077B5;
    padding: 10px 20px;
    border-radius: 50px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button:hover {
    background-color: #0077B5;
    color: var(--white-color);
}

.footer {
    clear: both;
    bottom: 0;
    width: 100%;
    background-color: #000;
    color: var(--white-color);
    padding: 50px 0;
}

.footer h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.footer p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer ul li {
    margin-bottom: 10px;
}

.footer ul li a {
    color: var(--white-color);
    text-decoration: none;
}

.footer-bottom {
    background-color: #222;
    color: var(--white-color);
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
}

.button-outline {
    background-color: transparent;
    color: #0077B5;
    border: 2px solid #0077B5;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    text-decoration: none;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

.button-outline:hover {
    background-color: #0077B5;
    color: var(--white-color);
}

@media screen and (max-width: 768px) {
    .hero-container {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        height: auto;
        padding: 20px;
    }

    .hero-content {
        width: 100%;
        text-align: left; 
        align-items: left;
    }

    .hero-title {
        font-size: 2.5rem; /* Increase the font size */
        margin-bottom: 0.5rem;
        text-align: left;
        align-items: left;
    }

    .hero-subtitle {
        font-size: 1.5rem; /* Increase the font size */
        margin-bottom: 1rem;
        text-align: left;
    }

    .button-primary {
        font-size: 1.2rem;
        padding: 0.8rem 1.6rem;
    }

    .features-section,
    .business-value-section,
    .pricing-section,
    .cta-section {
        padding: 2rem 1rem;
    }

    .feature-cards,
    .business-value-cards,
    .pricing-cards {
        flex-direction: column;
        align-items: center;
    }

    .feature-card,
    .business-value-card,
    .pricing-card {
        width: 100%;
        max-width: 400px;
        margin-bottom: 1rem;
    }

    .footer {
        padding: 2rem 0;
    }

    .business-value-cards {
        flex-direction: column;
        align-items: center;
    }

    .business-value-card {
        width: 100%;
        max-width: 400px;
        margin-bottom: 1rem;
    }
}

@media (max-width: 480px) {
    .hero-container {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        height: auto;
        padding: 20px;
    }

    .hero-content {
        width: 100%;
        text-align: left; 
        align-items: left;
    }

    .hero-title {
        font-size: 3rem; /* Increase the font size */
        margin-bottom: 0.5rem;
        text-align: left;
        align-items: left;
    }

    .hero-subtitle {
        font-size: 1.5rem; /* Increase the font size */
        margin-bottom: 1rem;
        text-align: left;
    }

    .button-primary {
        font-size: 1rem;
        padding: 0.8rem 1.6rem;
    }

    .business-value-cards {
        flex-direction: column;
        align-items: center;
    }

    .business-value-card {
        width: 100%;
        max-width: 400px;
        margin-bottom: 1rem;
    }
}
