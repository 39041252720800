.new-register {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.toggle-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.toggle-button {
    padding: 10px 20px;
    border: 1px solid #f83e3e;
    background-color: #f83e3e;
    color: #fff;
    cursor: pointer;
}


.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 300px;
}

.form p {
    font-size: 20px;
    font-weight: bold;
}

.form input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.form button {
    cursor: pointer;
    align-self: center;
    width: 100%;
    padding: 15px 30px;
    border-radius: 20px;
    border: none;
    background-color: #f83e3e;
    color: #fff;
    font-size: 18px;
}

.file-input-container {
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.file-input-container label {
    display: block;
    margin-bottom: 5px;
}

.file-input-container input[type="file"] {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.file-input-container .file-preview {
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    text-align: center;
}

.file-input-container .file-preview::before {
    content: attr(data-text);
}

.snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.button-primary {
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 15rem;
    border: 2px solid #f83e3e;
    background-color: transparent;
    color: #f83e3e;
    font-size: 1.5rem;
    text-decoration: none;
    transition: background-color 0.2s ease-out;
}
.toggle-button.active {
    background-color: white;
    border: 1px solid #f83e3e;
    color: #f83e3e;
}