.App {
  display: flex;
}

.sidebar {
  position: fixed;
  width: 80px;
  /* Increase the width */
  height: 100%;
  background-color: #f83e3e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-top-right-radius: 20px;
  /* Add rounded top right corner */
  border-bottom-right-radius: 20px;
  /* Add rounded bottom right corner */
}


.sidebar-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  color: white;
  text-decoration: none;
  font-size: 24px;
}

.nav-text .active {
  background-color: rgba(255, 255, 255, 0.1);
}

.logout-button {
  margin-top: auto;
}