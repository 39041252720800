.card {
    background-color: '#fff';
    padding: '20px';
    margin-top: '3%';
    margin-left: '2%';
    margin-right: '2%';
    margin-bottom: '2%';
    border-radius: '8px';
    box-shadow: '0 2px 4px rgba(0,0,0,0.2)';
    width: '96%';
    display: 'flex';
    justify-content: 'space-between';
  }
  
  .card-body {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
    width: '15%';
    padding: '20px';
    margin-top: '3%';
    margin-left: '2%';
    margin-right: '2%';
    margin-bottom: '2%';
  }
  .dashboardContent {
    flex-grow: 1; /* Ensures the main content takes the remaining space */
    overflow: auto; /* Adds scrolling to the main content if it exceeds the viewport height */
  }