.card {
  border: 1px solid #ccc;
  padding: 0.5rem; 
  margin-bottom: 1rem;
  display: inline-block;
  border-radius: 20px;
  width: 85%; 
  height: 85%;
  text-align: center;
  box-sizing: border-box; 
}

img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.tables-container {
  display: grid;
  border-radius: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap:2rem;
  margin: 1rem;
  justify-items: center;
  border: 1px solid #ccc; 
  padding: 1.5em; 
}

.card-header {
  background-color: #f5f5f5;
  font-weight: bold;
}

.card-body table {
  margin-bottom: 0;
}

.tableRowAvailable {
  background-color: #3190FF;
}

.tableRowBooked {
  background-color: #E59191;
}
.single-table {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  width: 200px;
  text-align: center;
}



button{
  background-color: #f83e3e; /* Change to the color you prefer */
  color: white;
  margin: 1%;
  padding: 5px 20px; /* Adjust the padding for a smaller size */
  font-size: 14px; /* Adjust the font size for smaller text */
  display: inline-block;
  transition-duration: 0.4s;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 17px;
}
button:hover {
  background-color: #fff;
  color: #f83e3e;
  border: #f83e3e solid 1px;
}
