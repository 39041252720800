.trust-showcase {
  background-color: #f8f9fa;
  padding: 2rem;
  text-align: center;
}

.trust-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
}

.trust-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.trust-logo {
  margin: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100px;
}

@media (max-width: 768px) {
  .trust-logos {
    flex-direction: column;
  }

  .trust-logo {
    width: 100%;
    max-width: 200px;
  }
}
