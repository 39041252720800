.newRegister {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.toggleButtons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.toggleButton {
    padding: 10px 20px;
    border: 1px solid #f83e3e;
    background-color: #f83e3e;
    color: #fff;
    cursor: pointer;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 300px;
}

.form p {
    font-size: 20px;
    font-weight: bold;
}

.form input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.form button {
    cursor: pointer;
    align-self: center;
    width: 100%;
    padding: 15px 30px;
    border-radius: 20px;
    border: none;
    background-color: #f83e3e;
    color: #fff;
    font-size: 18px;
}

.fileInputContainer {
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.fileInputContainer label {
    display: block;
    margin-bottom: 5px;
}

.fileInputContainer input[type="file"] {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.fileInputContainer .filePreview {
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    text-align: center;
}

.fileInputContainer .filePreview::before {
    content: attr(data-text);
}

.snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.buttonPrimary {
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 15rem;
    border: 2px solid #f83e3e;
    background-color: transparent;
    color: #f83e3e;
    font-size: 1.5rem;
    text-decoration: none;
    transition: background-color 0.2s ease-out;
}

.toggleButtonActive {
    background-color: white;
    border: 1px solid #f83e3e;
    color: #f83e3e;
}
.ingredientsSection {
    margin-top: 20px;
    width: 100%;
}

.ingredientInputWrapper {
    display: flex;
    gap: 10px;
}

.addIngredientButton {
    padding: 10px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.ingredientList {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.ingredientItem {
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.removeIngredientIcon {
    cursor: pointer;
    color: #f83e3e;
    margin-left: 10px;
}